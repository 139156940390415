import React from "react"
import Layout from '../layouts/Layout'
import MvpsSection from '../components/MvpsSection'
import PrepareData from "../utils/PrepareData"

const HomeTemplate = props => {
  const { pageContext: { items } } = props
  const shuffledItems = PrepareData.shuffleItems([...items])

  return (
    <Layout>
      <div>
        <tm-page class="theme--careers">
          <tm-hero-a
            background="dark"
            img-align="center"
            img-src="https://developer.salesforce.com/resources2/mvp/hero.png"
            img-alt="Trailhead characters climbing a Salesforce MVP sign"
            subtitle="Salesforce MVPs are exceptional leaders and product experts who go above and beyond to help others succeed in the Salesforce ecosystem."
            title="SALESFORCE MVPs"
            /*cta-href="https://www.salesforce.com/blog/mvp-nominations/"
            cta-label="Nominate a 2025 Salesforce MVP"
            cta-targrt="true" */
          />

          <tm-section-a background="light" title="Salesforce MVP and Hall of Fame" cta-title="Meet Them" cta-href="#meet">
            <tm-card-grid-a img-type="full">
              <tm-grid-item
                description="Salesforce MVPs are self or community-nominated Trailblazers recognized annually for their product expertise, leadership, and generosity in helping others learn Salesforce and connect to the Trailblazer Community."
                img-src="https://developer.salesforce.com/resources2/mvp/MVP+Card+01.svg"
                img-alt="Decorative image for the Salesforce MVPs card"
                title="Salesforce MVPs" />
              <tm-grid-item
                description="Salesforce MVP Hall of Fame members have achieved 5 years of consecutive Salesforce MVP renewal and are recognized as mentors to the next generation of Salesforce MVPs with lifetime Hall of Fame status."
                img-src="https://developer.salesforce.com/resources2/mvp/MVP+Card+02.svg"
                img-alt="Decorative image for the Salesforce MVP Hall of Fame Members card"
                title="Salesforce MVP Hall of Fame Members" />
            </tm-card-grid-a>
          </tm-section-a>

          <tm-section-a background="white" title="Salesforce MVP Program Pillars">
            <tm-card-grid-c img-aspect="60%" img-type="full">
              <tm-grid-item
                description="Salesforce MVPs maintain a strong understanding of Salesforce products and are eager to share their expertise through answering questions, authoring blogs, and more."
                img-src="https://developer.salesforce.com/resources2/mvp/Expertise.jpg"
                img-alt="Expertise icon"
                title="Expertise" />

              <tm-grid-item
                description="Salesforce MVPs consistently seek opportunities to innovate. They lead Trailblazer Community Groups, help underprivileged communities skill up on Salesforce, and participate in programs like Salesforce MVP Office Hours, Trailblazer Mentorship, and Community Conferences."
                img-src="https://developer.salesforce.com/resources2/mvp/Leadership.jpg"
                img-alt="Leadership icon"
                title="Leadership" />

              <tm-grid-item
                description="Salesforce MVPs are the ultimate connectors and mentors. In addition to maintaining their Salesforce knowledge, they’re always willing to share insights, ideas, and connections to help others succeed in the Salesforce ecosystem."
                img-src="https://developer.salesforce.com/resources2/mvp/Generosity.jpg"
                img-alt="Generosity icon"
                title="Generosity" />
            </tm-card-grid-c>
          </tm-section-a>

          <tm-section-a
            background="light"
            title="Salesforce MVP Nomination Process"
            /* cta-href={`https://www.salesforce.com/blog/mvp-nominations`}
            cta-title="Nominate Now" */ >
            <tm-card-grid-a img-aspect="60%" img-type="left">
              <tm-grid-item
                description="Trailblazers can self-nominate or nominate other Salesforce ecosystem professionals. We carefully review every nomination and you may submit as many nominations as you’d like. Current Salesforce MVPs are automatically considered for renewal."
                img-src="https://developer.salesforce.com/resources2/mvp/MVP+Nomination+01.svg"
                title="Community Nominations" />

              <tm-grid-item
                description="The Trailblazer Community team conducts initial nomination reviews. Then, Salesforce employees who are active in the Trailblazer Community conduct a review of nominations."
                img-src="https://developer.salesforce.com/resources2/mvp/MVP+Nomination+02.svg"
                title="Trailblazer Community Team and Salesforce Stakeholder Review" />

              <tm-grid-item
                description="Current Salesforce MVPs provide feedback to new nominations and nominations renewals based on their experience with nominees."
                img-src="https://developer.salesforce.com/resources2/mvp/MVP+Nomination+03.svg"
                title="Peer Feedback" />

              <tm-grid-item
                description="The Trailblazer Community team conducts final nomination reviews and the leadership team signs off on nomination decisions."
                img-src="https://developer.salesforce.com/resources2/mvp/MVP+Nomination+04.svg"
                title="Trailblazer Community Team Final Review" />
            </tm-card-grid-a>
          </tm-section-a>

          <tm-section-a background="white" title="Learn More About the Program">
            <tm-faq-a>
              <tm-faq-item title="What does it mean to be a Salesforce MVP?" description="Salesforce MVPs are product experts and community leaders. They are committed to continuing their learning, sharing their expertise, and finding ways to innovate within the Salesforce ecosystem. As leaders and Salesforce advocates, they attend regular meetings to understanding community happenings, provide product and program feedback, and stay in close contact with the Trailblazer Community team. We expect Salesforce MVPs to lead with kindness and inclusivity, and represent and respect the Salesforce brand by adhering to the Salesforce MVP Code of Conduct."></tm-faq-item>
              <tm-faq-item title="What is the selection criteria for being a Salesforce MVP?" description="The core pillars of the Salesforce MVP program are: expertise, leadership, and generosity. While there is no magic sauce to earning this title, here are examples of how current Salesforce MVPs have done it. Salesforce MVPs have been actively contributing to the Salesforce ecosystem for a minimum of 2 years and demonstrate expertise by holding Trailhead Ranger status, earning Salesforce credentials, creating technical content, and answering questions on the Trailblazer Community. They demonstrate leadership by starting a Trailblazer Community group, organizing a Community conference, or being a Trailblazer Mentor. They’re also generous with their knowledge and time, sharing their expertise in the Trailblazer Community or via social media, participating in pro-bono Salesforce implementations, or organizing community events like Salesforce Saturdays."></tm-faq-item>
              <tm-faq-item title="What are the perks of being a Salesforce MVP or Hall of Fame member?" description="With great responsibility comes great reward! There are many perks to being a Salesforce MVP or Hall of Fame member, including: annual recognition, access to private Slack channels, event invites, speaking engagements, certification and training opportunities, mentorship and professional development, and of course, swag!"></tm-faq-item>
              <tm-faq-item title="Where do I go if I have questions or feedback about the Salesforce MVP program?" description="Reach out to the Trailblazer Community team at trailblazercommunity@salesforce.com."></tm-faq-item>
            </tm-faq-a>
          </tm-section-a>

          <tm-section-a id="meet" background="dark" title="Salesforce MVP and Hall of Fame Member Directory" >
            <MvpsSection items={shuffledItems} />
          </tm-section-a>



          <tm-section-a background="white" title="Trailblazer Community Talks">
            <tm-youtube video-id="Gpggil0Znao"></tm-youtube>
          </tm-section-a>

          <tm-end-cap-a
            background="dark"
            cta-href="https://trailblazers.salesforce.com/about?&amp;utm_source=trailblazer_community&amp;utm_medium=events&amp;utm_campaign=TC2"
            img-src="https://developer.salesforce.com/resources2/mvp/campfire.png"
            cta-label="Learn More"
            subtitle="Learn, connect, have fun, and give back together."
            title="Join the Trailblazer Community" />
        </tm-page>

        <p>
          <th-element-loader element="tm-page" style={{ height: '100vh' }}></th-element-loader>
        </p>
      </div>

    </Layout>
  )
}

export default HomeTemplate